import React from 'react'
import Layout from '../components/Layout'
import Seo from '../components/SEO';

// Create pageData object to simulate markdownRemark object
const pageData = {
  frontmatter: {
    title: "Legal Notices",
    description: "Legal Notices",
  },
}

const IndexPage = () => (
  <Layout>
    <div>
      <Seo postData={pageData} />
      <div>Unless stated otherwise, all contents in this website are licensed by <a href="http://creativecommons.org/licenses/by/3.0/" title="Creative Commons BY 3.0" target="_blank" rel="noreferrer">CC 3.0 BY</a>.</div>

      <div>Github icon made by <a target="_blank" href="https://www.flaticon.com/authors/dave-gandy" title="Dave Gandy">Dave Gandy</a> from <a target="_blank" href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a> is licensed by <a href="http://creativecommons.org/licenses/by/3.0/" title="Creative Commons BY 3.0" target="_blank" rel="noreferrer">CC 3.0 BY</a>.</div>

      <div>"Note blog" icon made by <a target="_blank" href="https://www.flaticon.com/authors/yannick" title="Yannick">Yannick</a> from <a target="_blank" href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a> is licensed by <a href="http://creativecommons.org/licenses/by/3.0/" title="Creative Commons BY 3.0" target="_blank" rel="noreferrer">CC 3.0 BY</a>.</div>

      <div>"Home Icon Silhouette" and "Professional profile with image" icon made by <a target="_blank" href="http://www.freepik.com" title="Freepik">Freepik</a> from <a target="_blank" href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a> is licensed by <a href="http://creativecommons.org/licenses/by/3.0/" title="Creative Commons BY 3.0" target="_blank" rel="noreferrer">CC 3.0 BY</a>.</div>

      <div>Bitbucket icon made by <a target="_blank" href="https://www.iconfinder.com/Flatart" title="Flatart">Flatart</a> from <a target="_blank" href="https://www.iconfinder.com/icons/4373267/bitbucket_logo_logos_icon" title="Iconfinder">www.iconfinder.com</a>.</div>

      <div><a target="_blank" href="https://icons8.com/icon/pU44R9xgF3wq/google-scholar">Google Scholar</a> icon by <a target="_blank" href="https://icons8.com">Icons8</a></div>
    </div>
  </Layout>
)

export default IndexPage
